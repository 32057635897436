import React, { useState, useEffect } from 'react'
import "../assets/sass/contact.sass"

const ContactForm = () => {

  const initialMessageInfoState = {email: "", city: "", phoneNumber: "", name: "", message: "",database: null}
  const [stateInfo, setStateInfo] = useState(initialMessageInfoState);
  
  const isEnabled = stateInfo.email.length > 5 && stateInfo.name.length > 0 && stateInfo.city.length > 0 && stateInfo.phoneNumber.length > 0 && stateInfo.message.length > 0;

  return(

    <div className="contact-form-comp">
      <h1>Contact Us For A Free Estimate</h1>

      <form action="https://formsubmit.co/kevinslandscapingco@gmail.com" method="POST">
          <input placeholder="Name" type="text" name="name" value={stateInfo.name} onChange={e => setStateInfo({...stateInfo,name:e.currentTarget.value})} required/>
          <input placeholder="City" type="text" name="city" onChange={e => setStateInfo({...stateInfo,city:e.currentTarget.value})} required/>
          <input type="hidden" name="_subject" value="New costumer email"/>
          <input placeholder="Email" type="email" name="email" onChange={e => setStateInfo({...stateInfo,email:e.currentTarget.value})} required/>
          <input placeholder="Number" type="phoneNumber" name="number" onChange={e => setStateInfo({...stateInfo,phoneNumber:e.currentTarget.value})} required/>
          <textarea type="text" name="message" placeholder="Message" onChange={e => setStateInfo({...stateInfo,message:e.currentTarget.value})} required/>

          <input type="text" name="_honey" style={{display:"none"}}/>
          <input type="hidden" name="_template" value="table"/>
          <input type="hidden" name="_autoresponse" value="Thank you for chosing Kevins Landscaping, we'll get back to you asap."/>
          <input type="hidden" name="_next" value="https://kevinslandscaping.llc/"/>
          {/* <input type="hidden" name="_next" value="http://localhost:8000/contact"/> */}
          <button disabled={!isEnabled} type="submit">Send</button>
      </form>

    </div>
  )
}


export default ContactForm;