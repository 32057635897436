import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import Layout from "../components/layout"
// import SEO from "../components/seo"
import "../assets/sass/patios.sass"
import ContactForm from '../components/contactForm'

const Patios = () => (
<div className="info-page patios">

    <div className="info-page-intro">
        <h1>Patios</h1>
        <p>Helping you keep your property healthy, clean, and safe.</p>
    </div>
    <img src="https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/PicsArt_06-22-09.10.44.jpg?alt=media&token=aa16046c-c013-4954-9ae0-349b498dcbea" />

    <div className="discription">

        <p>
            At Kevins Fencing and landscaping, we’ll do whatever is needed to get your property ready for the next season.
            We come with years of experience and a reputation of doing exceptional jobs. 
            Getting your landscape back into shape after the end of the season can be a big job, and we have the team to tackle just that.
        </p>

    </div>
    <ContactForm />

</div>

)

export default Patios